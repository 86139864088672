<template>
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="kt-wrapper mt-5">
      <KTAside v-if="asideEnabled"></KTAside>
      <KTHeader></KTHeader>
      <KTSubheader
        v-if="subheaderDisplay"
        v-bind:breadcrumbs="breadcrumbs"
        v-bind:title="pageTitle"
      ></KTSubheader>
      <transition name="fade-in-up">
        <router-view></router-view>
      </transition>
      <KTFooter></KTFooter>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/views/theme/aside/Aside.vue";
import KTHeader from "@/views/theme/header/Header.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
import HtmlClass from "@/common/services/htmlclass.service";
import KTSubheader from "@/views/theme/subheader/Subheader.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/store/htmlclass.module.js";

export default {
  name: "Base",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    Loader
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    HtmlClass.init();
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters(["isAuthenticated", "breadcrumbs", "pageTitle", "layoutConfig"]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
