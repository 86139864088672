import { mapActions } from "vuex";

import { LOGIN } from "@/store/auth.module";
import { isProduction } from "@/common/config";

const mixin = {
  data() {
    return {
      currentUserIndex: 0,
      users: ["MANAGER", "STUDENT_1", "STUDENT_2", "PARENT_1"]
    };
  },
  methods: {
    ...mapActions([LOGIN]),
    changeUser() {
      if (isProduction()) return;

      if (this.currentUserIndex === this.users.length - 1) {
        this.currentUserIndex = 0;
      } else {
        this.currentUserIndex += 1;
      }
      const login = this[LOGIN];
      login(this.users[this.currentUserIndex]);
    }
  }
};

export default mixin;
