<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-wrapper" id="kt_user_toggle">
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-username" @click="changeUser">
            {{ currentUser.NAME_WITH_ROLE }}
          </span>
          <!-- <img alt="Pic" src="@/assets/media/users/default.jpg" /> -->
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        </div>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import { mapGetters } from "vuex";

import ChangeUser from "@/components/mixins/change-user.mixin";

export default {
  name: "KTTopbar",
  mixins: [ChangeUser],
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
